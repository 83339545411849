import feature1 from "../images/features/1.png";
import feature2 from "../images/features/2.png";
import feature3 from "../images/features/3.png";
import feature4 from "../images/features/4.png";

export const LandingPageBulletPoints = [
    {
        leftText: "Tea or coffee… ",
        leftSubtext: "all in one infuser",
        rightText: "One infuser with two filters for loose tea and coffee. InfusinGo can be attached to the cup, hence easy to carry and store."
    },
    {
        leftText: "At work or home… ",
        leftSubtext: "brew it wherever",
        rightText: "No need to wait in the kitchen for steeping and disposing tea. Brew it at your work desk, on the sofa, in the garden or wherever you are…"
    },
    {
        leftText: "Light or dark tea… ",
        leftSubtext: "control the strength whenever ",
        rightText: "infusinGo can be kept inside the cup while drinking, therefore tea can be used again to make it stronger any time."
    },
    {
        leftText: "One or two cups…  ",
        leftSubtext: "use and use again flavoured tea",
        rightText: "Most flavoured tea leaves can be used more than once. infusinGo is ready to go for the second round, just need to add some hot water."
    }
]


export const Features = [
    {
        title: "Tea or Coffee - all in one infuser",
        description: "Even though loose tea tastes better, everyone\
        brew tea in a bag for convenience. Brewing loose\
        tea and coffee need separate tools infuser,\
        strainer, filter and spoon too. Still it's a messy job,\
        not quick and easy. infusinGo comes handy it\
        attachable to the cup. So easy to carry and store.\
        Ideal for drinking loose tea at work,",
        image: feature1
    },
    {
        title: "At work or home - anytime anywhere",
        description: "The reason that infusinGo is such an exciting\
        innovation is that we have built convenience right\
        into it. Instead of throwing away used tea, as you\
        would usually, you can retain the used tea inside\
        the infuser without brewing further. That means\
        you can use infusinGo even when you don't have\
        access to a trashcan in your office, living room or\
        garden.",
        image: feature2
    },
    {
        title: "Light or dark - pick your strength",
        description: "Another advantage of infusinGo is that you decide\
        how strong your tea or coffee is going to be. From\
        here on out, you are in complete control of your\
        tea-drinking or coffee-drinking experience. Use it\
        to wake yourself up in the morning or to keep\
        yourself moving in the afternoon. Idea when\
        making tea or coffee for the whole family.",
        image: feature3
    },
    {
        title: "Conserve Your Teas",
        description: "Something that most people don't realize is that\
        you can use your tea leaves more than once.\
        infusinGo makes that easy too, keeping your tea\
        leaves in place for you so that you can brew them\
        and make the most of your favorites.",
        image: feature4
    }
]