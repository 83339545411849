import React from "react";
import "./styles.scss";

const Feature = ({title, subtitle, image, imagePosition, fullSizeImage = false}) => {

    const renderDesktopImage = () => {
        return (
            <img alt={subtitle} src={image} width={fullSizeImage ? "50%" : "20%"}/>
        )
    }

    const renderDesktopSectionWithImageRight = () => {
        return (
            <div className="section-desktop__content">
                <div className="section-desktop__content__text">
                    <h1> {title} </h1>
                    <p> {subtitle} </p>
                </div>
                {renderDesktopImage()}
            </div>
        )
    }

    const renderDesktopSectionWithImageLeft = () => {
        return (
        <div className="section-desktop__content">
            {renderDesktopImage()}
            <div className="section-desktop__content__text">
                <h1> {title} </h1>
                <p> {subtitle} </p>
            </div>
        </div>
        )
    }

    return (
        <>
            <section className="section-desktop">
                {
                    imagePosition === "right" ? renderDesktopSectionWithImageRight()
                    : renderDesktopSectionWithImageLeft()
                }
            </section>
            <section className="section-mobile" style={{padding: fullSizeImage ? "0rem": "0.5rem"}}>
                <h1> {title} </h1>
                <img alt={subtitle} src={image} width={fullSizeImage ? "100%" : "25%"}/>
                <p> {subtitle} </p>
            </section>
        </>
        )
}

export default Feature;