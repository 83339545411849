import React from "react";
import styled from "styled-components";
import heroImage from "../../images/home-bg.jpg";
import heroImageMobile from "../../images/home-bg-mobile.jpg";

const WrapperDiv = styled.div`
    padding: 1rem;
    display: flex;
    justify-content: center;
    text-align: center;
`

const HeroImage = styled.img`
    margin-top: 100px;
    width: 100vh;
`

const HeroImageMobile = styled(HeroImage)`
    display: none;

    @media (max-width: 768px) {
        display: block;
    }
`

const HeroImageDesktop = styled(HeroImage)`
    display: block;

    @media (max-width: 768px) {
        display: none;
    }
`

const CenterTextDiv = styled.div`
    position: absolute;
    bottom:0;
    font-weight: 700;
    font-size: 25px;
    color: #000;
`
export default function HomeHero() {
    return (
        <WrapperDiv>
            <HeroImageMobile src={heroImageMobile}/>
            <HeroImageDesktop src={heroImage}/>
            <CenterTextDiv> brewing loose tea and coffee made easy ! </CenterTextDiv>
        </WrapperDiv>
    )
}