import * as React from "react"
import Helmet from "react-helmet";
import Feature from "../components/Feature";
import { Features } from "../enums/LandingPageEnums";

import gif1 from "../images/gifs/1.gif";
import gif2 from "../images/gifs/2.gif";
import image3 from "../images/3.jpg";
import "../css/index/styles.scss";
import JoinWaitlistForm from "../components/JoinWaitlistForm";
import Header from "../components/Header";
import HomeHero from "../components/HomeHero";

const IndexPage = () => {
  return (
    <>
      <Helmet title="Infusingo">
        <meta property="og:title" content="Infusingo"/>
      </Helmet>
      <Header/>
      <HomeHero/>
      <Feature
      title="How does it work ?"
      subtitle="Just add loose tea leaves and hot water"
      image={gif1}
      imagePosition="right"
      fullSizeImage={true}
      />
      <Feature
      title="Now ready to brew it"
      subtitle="Wherever you are..."
      image={gif2}
      imagePosition="left"
      fullSizeImage={true}
      />
      <img src={image3} style={{width: "100%"}} alt="Decorative mugs"/>
        {
          Features.map((feature, index) => {
            return (
              <Feature
              key={index}
              title={feature.title}
              subtitle={feature.description}
              image={feature.image}
              imagePosition={index % 2 === 0 ? "left" : "right"}
              />
            )
          })
        }
        <section id="JoinWaitlist">
          <JoinWaitlistForm/>
        </section>
      </>
  )
}

export default IndexPage;