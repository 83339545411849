import { CircularProgress } from "@material-ui/core";
import axios from "axios";
import React from "react";
import styled from "styled-components";
import "./JoinWaitlistForm.module.scss";

const Wrapper = styled.div`
    background-color: indianred;
    color: white;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;

    @media (max-width: 768px){
        flex-direction: column;
    }
`;

const TextField = styled.input`
    background-color: transparent;
    color: white;
    border: none;
    border-bottom: 1px solid white;
    padding: 0.25rem;
    margin: 0.5rem;
    font-size: 16px;
`;

const Button = styled.button`
    margin: 0.5rem;
    padding: 0.25rem;
    width: 100%;
    background-color: white;
    border: none;
    border-radius: 0.25rem;
    font-size: 15px;
    color: #cd5b5c;
    align-self: center;
    cursor: pointer;
`;

const CenteredDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const FlexDiv = styled.div`
    display: flex;

    @media (max-width: 768px) {
        flex-direction: column;
    }
`

export default class JoinWaitlistForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            formSubmitted: false,
            formSubmissionInProgress: false,
            formSubmissionStatus: null
        }
    }

    updateFirstName = (event) => {
        this.setState({firstName: event.target.value});
    }

    updateLastName = (event) => {
        this.setState({lastName: event.target.value});
    }

    updateEmail = (event) => {
        this.setState({email: event.target.value});
    }

    submitForm = async (event) => {
        event.preventDefault();
        if (this.state.firstName && this.state.lastName && this.state.email) {
            this.setState({formSubmissionInProgress: true});
            try {
                await axios.post(`${process.env.GATSBY_API_URL}/waitlist`, {
                    firstName: this.state.firstName,
                    lastName: this.state.lastName,
                    email: this.state.email
                });
                this.setState({formSubmissionInProgess: false, formSubmitted: true, formSubmissionStatus: 200});
            } catch (error) {
                this.setState({formSubmissionInProgess: false, formSubmitted: true, formSubmissionStatus: error.response.status});
            }
            
        }

    }

    renderFormErrorMessage = () => {
        let errorMessage = "";
        switch(this.state.formSubmissionStatus) {
            case 400:
                errorMessage = "This email has already been used to subscribe to product updates."
                break;
            default:
                errorMessage = "We ran into a problem. Please try again later."
                break;
        }
        return (
            <div>
                <h2> We couldn't sign you up for updates. </h2>
                <p> {errorMessage} </p>
            </div>
        )
    }

    renderFormSuccessMessage = () => {
        return (
            <div>
                <h2> Thanks for signing up, {this.state.firstName} ! </h2>
                <p> You'll receive product updates at {this.state.email}. </p>
            </div>
        )
    }

    renderForm = () => {
        return (
            <>
            {
                !this.state.formSubmissionInProgress ?
                <>
                <div>
                    <h1> Get Product Updates </h1>
                    <p> Be notifed of Kickstarter campaign updates, product releases, and more</p>
                </div>
                <form onSubmit={this.submitForm}>
                    <FlexDiv>
                        <TextField type="text" placeholder="First name*" value={this.state.firstName} onChange={this.updateFirstName} required />
                        <TextField type="text" placeholder="Last name*" value={this.state.lastName} onChange={this.updateLastName} required/>
                    </FlexDiv>
                    <FlexDiv>
                        <TextField type="text" placeholder="Email address*" value={this.state.email} onChange={this.updateEmail} required/>
                        <Button type="submit"> Notify Me </Button> 
                    </FlexDiv>
                </form> 
                </>
                :
                <CenteredDiv>
                <p> Signing up for notifications. This may take a few moments...</p>
                <CircularProgress style={{color: "white"}}/>
                </CenteredDiv>
            }
            </>
            
        )
    }

    render() {
        return(
            <Wrapper>
                {
                    !this.state.formSubmitted ? this.renderForm() 
                    : 
                    this.state.formSubmissionStatus === 200 ? this.renderFormSuccessMessage() : this.renderFormErrorMessage()
                }

            </Wrapper>
        )
    }
    }
