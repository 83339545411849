import React from "react";
import infusingologo from "../../images/infusingo-logo.png";
import eztcologo from "../../images/eztco-logo.png";
import styled from 'styled-components';

const Wrapper = styled.div`
    display: flex;
    width: 100%;
    height: 52px;
    justify-content: space-between;
    position: fixed;
    padding-bottom: 3rem;
    background-color: #fff;
    border-bottom: 5px solid gold;
    padding-top: 0.5rem;
    z-index: 10;

    @media (max-width: 768px) {
        padding-top: 1rem;
        padding-bottom: 2rem;
    }
}
`

const Image = styled.img`
    padding: 1rem;
`

export default function Header() {

    return (
        <Wrapper>
            <Image src={infusingologo} alt="Infusingo logo" width="200px" height="65px"/>
            <Image src={eztcologo} alt="EZTCO logo" width="60px" height="65px"/>
        </Wrapper>
    )
}